import axios from "axios";
import React, { useEffect, useState } from "react";
import QrScanner from "react-qr-scanner";

const Admin = ({setAuthToken, setIsAdminUser}) => {
  const handleError = (err) => {
    console.error(err);
  };

  const handleScan = async (data) => {
    if (data) {
      console.log(data);
      try {
        const response = await axios.post(
          "https://api.virtualtechfrontier.com/wp-json/api/bs-redeem",
          {
            user_id: data.text
          }
        );
        console.log(response);
        if (response.data.message == "Gift is redeemed") {
          setIsScannerOpen(false);
        }
      } catch (error) {
        console.log("Error Stamping");
      }
    }
  };

  const [isScannerOpen, setIsScannerOpen] = useState(false);
  return (
    <>
      <div className="w-full h-full flex flex-col justify-center items-center">
        {isScannerOpen ? (
          <div
            id="scanner"
            className="flex justify-center items-center w-[100%] h-[38vh]"
          >
            <QrScanner
              delay={300}
              className="w-[100%] h-[100%]"
              onError={handleError}
              onScan={handleScan}
              constraints={{ video: { facingMode: "environment" } }}
            />
          </div>
        ) : null}
        <button
          onClick={() => setIsScannerOpen(!isScannerOpen)}
          className="h-[8%] text-white font-semibold bg-[#054031] px-[10%] rounded-xl"
        >
          {isScannerOpen ? "Close " : "Open "}Scanner
        </button>
        <button
          onClick={() => {setIsAdminUser(false); setAuthToken(null);}}
          className="h-[8%] text-white font-semibold bg-[#054031] px-[10%] rounded-xl mt-5"
        >
          Log Out
        </button>
      </div>
    </>
  );
};
export default Admin;
