import React, { useState, useRef, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import qrCode from "../../img/qr-code.svg";
import stamp from "../../img/stamp.svg";
import completedStamp from "../../img/completedStamp.svg";
import close from "../../img/close.svg";
import axios from "axios";
import QrScanner from "react-qr-scanner";

const Home = () => {
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };
  const profilePicture = "https://www.w3schools.com/howto/img_avatar.png";
  const authToken = JSON.parse(getCookie("authToken"));
  const uid = authToken.user_id;
  const username = authToken.name;
  const [quads, setQuads] = useState([
    { id: 1, name: "The Perfect Match", description: 'Complete the Challenge and claim your stamp'},
    { id: 2, name: "SKINCARE FOR A BETTER YOU", description: 'Select your team & Unlock the secret to great skin'},
    { id: 3, name: "Be A Changemaker!", description: 'Take a picture of your graduation to redeem your graduation kit'},
  ]);
  const [completedQuads, setCompletedQuads] = useState([false, false, false]);
  const [isQrOpen, setIsQrOpen] = useState(false);
  const [QRsize, setQRsize] = useState(0);
  const [redeemable, setRedeemable] = useState(0); // 0 == not redeemable, 1 == redeemable, 2 == already redeemed

  const [viewportWidth, setViewportWidth] = useState(window.innerWidth * 0.9);
  const [viewportHeight, setViewportHeight] = useState(
    window.innerHeight * 0.38
  );

  const handleLogout = () => {
    document.cookie =
      "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.reload();
  };

  const getUserData = async () => {
    try {
      const response = await axios.post(
        "https://api.virtualtechfrontier.com/wp-json/api/bs-check-stamp",
        {
          user_id: uid,
        }
      );
      const user_data = response.data[0];
      const checkQuads = [false, false, false];
      if (user_data.stamp_1 == "1") {
        checkQuads[0] = true;
      }
      if (user_data.stamp_2 == "1") {
        checkQuads[1] = true;
      }
      if (user_data.stamp_3 == "1") {
        checkQuads[2] = true;
      }
      setCompletedQuads(checkQuads);
      if (user_data.redeem_status == "1") {
        setRedeemable(2);
      } else if (!checkQuads.includes(false)) {
        setRedeemable(1);
      }
      return response.data[0];
    } catch (error) {
      console.log("Error returning user data");
    }
  };

  useEffect(() => {
    // window.scrollTo(0, 1);
    getUserData();
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
      setViewportHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleQrOpen = () => {
    const newSize = Math.max(viewportWidth, viewportHeight) * 0.85;
    setQRsize(newSize);
    setIsQrOpen(true);
  };

  const handleButtonClick = () => {
    handleQrOpen(); // Open QR code
  };

  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const ScannerContainerRef = useRef(null);

  const handleScannerOpen = () => {
    setIsScannerOpen(true);
  };

  const handleScan = async (data) => {
    if (data) {
      try {
        const response = await axios.post(
          "https://api.virtualtechfrontier.com/wp-json/api/bs-stamping",
          {
            user_id: uid,
            booth: data.text,
          }
        );
        console.log(response);
        if (response.data.message == "Stamping Successfully") {
          getUserData();
          setIsScannerOpen(false);
        }
      } catch (error) {
        console.log("Error Stamping");
      }
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  return (
    <div id="homeParent" className="w-full h-[100%] flex flex-col">
      <div
        id="profileBar"
        className="z-9 flex flex-row bg-[#054031] w-full h-[8%] px-5"
      >
        <div id="leftBar" className="flex flex-row min-w-[50%] max-w-[70%] items-center h-full">
          <img
            src={profilePicture}
            alt="profile picture"
            className="rounded-full border border-[1.5px] border-white object-contain h-[5vh]"
          />
          <p className="text-white ml-2 text-[2.5vh] overflow-x-hidden">{username}</p>
        </div>
        <button
          id="rightBar"
          className="flex flex-row justify-end items-center w-[50%]"
          onClick={handleLogout}
        >
          <p className="text-white ml-2 text-[2.5vh]">Logout</p>
        </button>
      </div>

      <div
        id="content"
        className="relative h-[92%] w-full flex flex-col justify-end items-center"
      >
        <div
          id="visitRedeem"
          className="relative w-full flex justify-center h-[30%]"
        >
          <div className="absolute bottom-0 flex flex-col justify-start items-center bg-white bg-opacity-90 w-[80vw] rounded-t-[16px] h-[22dvh] pt-2 px-4 z-0">
            <p className="font-bold text-[#054031] text-[3vh]">
              Visit & Redeem
            </p>
            <p className="text-[2vh]">
              Visit all 3 Quad booths to participate and redeem a "Graduation
              Kit"
            </p>
            <p className="text-[#054031] text-[2vh] font-semibold mt-[2%]">
              {completedQuads.filter((value) => value === true).length}/3
              Complete
            </p>
          </div>
        </div>

        <div
          id="stamps"
          className="relative h-[70%] w-full z-1 flex flex-col items-center bg-[#F6F6F6] border-t border-[#054031]  overflow-y-scroll pt-[10%]"
        >
          {quads.map((quad) => (
            <div
              key={quad.id}
              className="relative rounded-[8px] border-[1px] bg-white border-[#D6D6D6] w-[90%] mb-[20px]"
            >
              <div className="text-[#054031] font-semibold bg-[#F6F6F6] rounded-t-[8px] border-b-[1px] border-[#D6D6D6] text-start text-[2.3dvh] py-[3%] px-[5%]">
                Quad {quad.id} •{" "}
                {completedQuads[quad.id - 1] ? "Complete" : "Not Complete"}
              </div>
              <div className="flex flex-row w-[65%] px-[5%] my-[5%]">
                <div>
                  <p className="text-[#054031] text-start font-bold text-[2.3dvh]">
                    {quad.name}
                  </p>
                  <p className="text-start mt-1 text-[2dvh]">
                    {quad.description}
                  </p>
                </div>
              </div>
              <div
                onClick={!completedQuads[quad.id - 1] ? handleQrOpen : null}
                className={`absolute rounded-full ${
                  !completedQuads[quad.id - 1] ? "bg-[#F6F6F6]" : "bg-[#054031]"
                } border-[1px] border-[#D6D6D6] h-[13dvh] w-[13dvh] flex justify-center items-center right-[7%] top-[15%]`}
              >
                  <div className="relative flex w-[100%] justify-center items-center" >
                    {!completedQuads[quad.id - 1] ? (
                      <><img
                      src={stamp}
                      alt="Not completed stamp"
                      className="w-[70%] h-auto"
                    />
                    <p className="absolute text-[1.7dvh] text-[#054031] font-semibold">Open QR</p></>
                    ) : (<><img
                      src={completedStamp}
                      alt="Completed stamp"
                      className="w-[70%] h-auto"
                    />
                    <p className="absolute text-white text-[1.7dvh] text-semibold">
                      VISITED
                    </p></>)}
                  </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={handleButtonClick}
          disabled={redeemable === 0 || redeemable === 2}
          className={`absolute top-[27%] w-[55%] shadow-xl font-semibold text-[2dvh] z-15 rounded-[8px] w-[220px] h-[6dvh] ${
            redeemable === 0 || redeemable === 2 ? "text-opacity-30" : ""
          } ${
            redeemable === 2
              ? "bg-[#DFDFDF] text-black"
              : "bg-[#054031] text-white"
          }`}
        >
          {redeemable == 2 ? "Redeemed" : "Redeem Gift"}
        </button>
      </div>
      {isQrOpen ? (
        <>
          <div
            id="QrBackground"
            className="bg-black bg-opacity-50 absolute z-10 h-[100dvh] w-[100dvw] flex justify-center items-start"
          >
            <div className="bg-[#054031] w-[90dvw] rounded-3xl py-[2dvh] mt-[20%]">
              <div className="flex flex-row justify-end mr-[5%] mt-[2%] mb-[2%]">
                <button
                  onClick={() => {
                    setIsQrOpen(false);
                    window.location.reload();
                  }}
                >
                  <img src={close} alt="close button" />
                </button>
              </div>
              <div
                id="qr"
                ref={ScannerContainerRef}
                className="flex justify-center items-center w-[100%] h-[50dvh]"
              >
                <QRCodeSVG
                  value={uid}
                  level="M"
                  size={QRsize}
                  className="rounded-2xl border-[8px] border-white"
                />
              </div>
              <p className="text-white text-xl font-semibold mt-[1%]">
                User ID: {uid}
              </p>
            </div>
          </div>
        </>
      ) : null}

      {isScannerOpen ? (
        <>
          <div
            id="scannerBackground"
            className="bg-black bg-opacity-50 absolute z-10 h-[100dvh] w-[100dvw] flex justify-center items-start"
          >
            <div
              ref={ScannerContainerRef}
              className="bg-[#054031] w-[90dvw] rounded-3xl py-[2.5dvh] mt-[20%]"
            >
              <div className="flex flex-row justify-end mr-[5%] mt-[2%] mb-[5%]">
                <button onClick={() => setIsScannerOpen(false)}>
                  <img src={close} alt="close button" />
                </button>
              </div>
              <div
                id="scanner"
                className="flex justify-center items-center w-[100%] h-[38dvh]"
              >
                <QrScanner
                  delay={300}
                  className="w-auto h-[100%]"
                  onError={handleError}
                  onScan={handleScan}
                  constraints={{ video: { facingMode: "environment" } }}
                />
              </div>
              <p className="text-white text-xl font-semibold mt-[2%]">
                User ID: {uid}
              </p>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};
export default Home;
