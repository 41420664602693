import "./App.css";

import RegistrationPage from "../src/screens/Registration/registration";
import Home from "../src/screens/Home/home";
import Admin from "../src/screens/Admin/Admin";
import { useState } from "react";
function App() {
  const [authToken, setAuthToken] = useState(null);
  const [isAdminUser, setIsAdminUser] = useState(false);

  return (
    <div className="App h-[100dvh] flex flex-col">
      <div id="NonFooter" className="h-[90%] w-full">
        {!authToken ? (
          <RegistrationPage
            setAuthToken={setAuthToken}
            setIsAdminUser={setIsAdminUser}
          />
        ) : isAdminUser ? (
          <>
            <Admin
              setAuthToken={setAuthToken}
              setIsAdminUser={setIsAdminUser}
            />
          </>
        ) : (
          <>
            <Home />
          </>
        )}
      </div>
      <div
        id="footer"
        className="h-[10%] w-full bg-[#054031] text-white text-[1.5vh] flex flex-col justify-center"
      >
        <div id="copyright" className="mb-[1vh]">
          <p>© 2024 The Body Shop International Limited.</p>
          <p>All rights reserved.</p>
        </div>
        <p id="poweredBy">Powered by Virtualtech Frontier Sdn. Bhd.</p>
      </div>

      {/* <header className="App-header flex justify-center h-[91vh]">
        {!authToken ? (
          <div className="h-[100%] flex justify-center items-start w-full py-[10%]">
            <RegistrationPage setAuthToken={setAuthToken} setIsAdminUser={setIsAdminUser}/>
          </div>
        ) : isAdminUser ? (<><Admin setAuthToken={setAuthToken} setIsAdminUser={setIsAdminUser}/></>) : (
          <><Home /></>
        )}
      </header>
      <div
        id="bottomCard"
        className="fixed bottom-0 left-0 w-full flex flex-col justify-between items-center h-[9vh] py-[1vh] bg-[#054031] text-white text-[1.5vh]"
      >
        <div id="copyright" className="mb-[1vh]">
          <p>© 2024 The Body Shop International Limited.</p>
          <p>All rights reserved.</p>
        </div>
        <p id="poweredBy">Powered by Virtualtech Frontier Sdn. Bhd.</p>
      </div> */}
    </div>
  );
}

export default App;
