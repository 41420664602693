import React, { useEffect, useState } from "react";
import axios from "axios";
import Logo from "../../img/Logo.png";

const Registration = ({ setAuthToken, setIsAdminUser }) => {
  const [hasAccount, sethasAccount] = useState(false);
  const [name, setName] = useState("");
  const [phone_num, setPhoneNum] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const [registeredPhoneNumError, setRegisteredPhoneNumError] = useState(false);
  const [noNameError, setNoNameError] = useState(false);
  const [noPhoneNumError, setNoPhoneNumError] = useState(false);
  const [shortPhoneNumError, setShortPhoneNumError] = useState(false);
  const [invalidChar, setInvalidChar] = useState(false);

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(";").shift();
    return null;
  };

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  };

  const loginUser = async () => {
    // Delete before deployment///////////////////////////////////
    if (phone_num == "0000000000") {
      setIsAdminUser(true);
      setAuthToken(true);
      return;
    }
    //////////////////////////////////////////////////////////////

    try {
      const response = await axios.post(
        "https://api.virtualtechfrontier.com/wp-json/api/bs-login",
        {
          login_id: phone_num,
        }
      );
      if (response.data === 0) {
        setInvalidLogin(true);
      } else {
        const token = JSON.stringify(response.data[0]);
        setCookie("authToken", token, 1); // stores session token for 1 day in cookies
        setInvalidLogin(false);
        setAuthToken(getCookie("authToken"));
      }
    } catch (error) {
      console.log("Invalid phone number");
    }
  };

  const registerUser = async () => {
    setInvalidChar(false);
    setShortPhoneNumError(false);
    setNoNameError(false);
    setNoPhoneNumError(false);
    setRegisteredPhoneNumError(false);
    try {
      const response = await axios.post(
        "https://api.virtualtechfrontier.com/wp-json/api/bs-registration",
        {
          name: name,
          phone_num: phone_num,
        }
      );
      if (response.data.message == "Please fill in all required info") {
        if (name == "") {
          setNoNameError(true);
        } 
        if (phone_num == "") {
          setNoPhoneNumError(true);
        }
      }
      if (response.data.message == "Phone Number is registered") {
        setRegisteredPhoneNumError(true);
      } else if (phone_num.length < 8 || phone_num.length > 11) {
        setShortPhoneNumError(true);
      } else if (!/^-?\d+$/.test(phone_num)) {
        setInvalidChar(true);
      } else if (response.data.code == 200) {
        loginUser();
      }
    } catch (error) {
      console.log("Invalid name or phone number");
    }
  };

  const handleSubmit = () => {
    if (!hasAccount) {
      console.log("Registering user");
      registerUser();
    } else {
      console.log("Logging in user");
      loginUser();
    }
  };

  useEffect(() => {
    const token = getCookie("authToken");
    if (token) {
      setAuthToken(token);
    }
  }, []);

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handlePhoneNumChange = (e) => {
    setPhoneNum(e.target.value);
  };

  return (
    <div className="flex justify-center items-center h-[100%]">
      <div
        id="registrationBackground"
        className="bg-white bg-opacity-90 max-h-[95%] w-[95%] rounded-[16px] flex flex-col items-center py-[5%] px-[1%]"
      >
        <div className="w-full h-[35%] flex flex-col justify-between items-center mb-[3%]">
          <img src={Logo} alt="logo" className="w-[65%] h-auto" />
          <p className="text-[#054031] text-[3.5dvh] mt-[3%] font-semibold">
            {!hasAccount ? "Registration" : "Login"}
          </p>
        </div>
        <div className="h-[62%] w-full px-[1%]">
          {!hasAccount ? (
            <>
              <div
                id="nameInput"
                className="flex flex-col items-start mb-[2dvh]"
              >
                <label className="text-black text-[2.5dvh] font-semibold mb-1">
                  Name
                </label>
                <input
                  value={name}
                  onChange={(e) => handleNameChange(e)}
                  className="bg-black bg-opacity-[10%] text-[2.5dvh] text-black rounded-lg w-full px-3 h-[7dvh]"
                />
                <p className="text-[#E83F36] text-[2dvh]">
                  {noNameError
                    ? "Please enter your name."
                    : ""}
                </p>
              </div>
            </>
          ) : null}

          <div id="mobileInput" className="flex flex-col items-start mb-[3dvh]">
            <label className="text-black text-[2.5dvh] font-semibold mb-1">
              Mobile Number
            </label>
            <input
              type="tel"
              value={phone_num}
              onChange={(e) => handlePhoneNumChange(e)}
              className="bg-black bg-opacity-[10%] text-black text-[2.5dvh] rounded-lg w-full px-3 h-[7dvh]"
            />
            <p className="text-[#E83F36] text-[2dvh] text-start">
              {hasAccount && noPhoneNumError
                ? "Mobile number is required to login."
                : !hasAccount && noPhoneNumError
                ? "Please enter your mobile number."
                : shortPhoneNumError
                ? "Please enter a valid mobile number."
                : invalidChar
                ? "Mobile number can only contain digits."
                : hasAccount && invalidLogin
                ? "Mobile number not recognized. Please check your entry or register."
                : registeredPhoneNumError
                ? "Mobile number is already registered."
                : ""}
            </p>
          </div>
          <button
            onClick={handleSubmit}
            // disabled={buttonDisabled}
            className="bg-[#054031] w-[95%] rounded-lg py-[1.5dvh] mb-[5%] text-[2dvh] text-white"
          >
            {!hasAccount ? "Register & Enter" : "Login"}
          </button>
          <div className="flex flex-row justify-center font-semibold text-[2.3dvh]">
            <p className="text-black">
              {!hasAccount ? "Already " : "Don't "} have an account? &nbsp;
            </p>
            <a
              className="text-[#054031] hover:cursor-pointer"
              onClick={() => {
                sethasAccount(!hasAccount);
                setName("");
                setPhoneNum("");
                setNoPhoneNumError(false);
                setNoNameError(false);
                setShortPhoneNumError(false);
                setInvalidChar(false);
                setInvalidLogin(false);
                setRegisteredPhoneNumError(false);
              }}
            >
              {!hasAccount ? "Login" : "Register"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Registration;
